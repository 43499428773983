import type firebase from 'firebase';
type Timestamp = firebase.firestore.Timestamp;

export enum PlatformMediaCustomableName {
  BIG_LOGO = 'big-logo',
  LOTTIE_LOADER = 'lottie-loader',
  VIDEO_LOADER = 'video-loader',
  // SMALL_LOGO = 'small-logo', // not currently in use
  FAVICON = 'favicon',
  // LANDSCAPE_WARNING = 'landscape-warning', TODO: not in focus
  SESSION_PLACEHOLDER = 'session-placeholder',
}

export interface PlatformMediaFile {
  key: PlatformMediaCustomableName;
  url: string;
  readonly mediaKind: string;
  uploadedAt: Timestamp;
}

export enum PlatformTextPropertiesName {
  PRIMARY_COLOR = 'primary-color',
  SECONDARY_COLOR = 'secondary-color',
  LIGHT_COLOR = 'light-color',
  // DANGER_COLOR = 'danger-color',
  // BORDER_RADIUS = 'border-radius',
  // COUPON_COLOR = 'coupon-color',
  TITLE = 'title',
  DEFAULT_ACTIVE_STORE = 'default-active-store',
}
export interface PlatformTextProperties
  extends Record<PlatformTextPropertiesName | string, PlatformCssProperty> {}
export interface PlatformCssProperty {
  key: PlatformTextPropertiesName;
  value: string;
  uploadedAt: Timestamp;
}

export interface LegalContentItem {
  title: string;
  htmlContent: string;
}

export interface LegalContentSettings {
  overridden: boolean;
  items: LegalContentItem[];
}
