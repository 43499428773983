import type firebase from 'firebase';
type Timestamp = firebase.firestore.Timestamp;

// on sdk mode, we are only logging the request to pay, and the client is responsible to complete the payment
export enum PaymentSuppliers {
  Stripe = 1,
  Cardcom = 2,
  Coriunder = 3,
  T1 = 4,
  Claro = 5,
  Sears = 6,
  ShopifyPermalinks = 7,
  SDK = 8,
  SDKWithRedirect = 9,
  WooCommerce = 10,
}

// TODO: SPLIT THIS TO 2 MODELS: ONE FOR THE PAYMENT PROVIDER AND ONE FOR THE CURRENCY
export class DbCurrencyModel {
  id: string;
  name: string;
  displayName: string;
  isoCode: string;
  paymentSupplier: PaymentSuppliers;
  multiplyByAmountOnCheckout?: number;
  flagClass: string;
}

export enum PaymentPurpose {
  SessionEntranceFee = 1,
  CartCheckout = 2,
}

export class DbPaymentModel {
  id: string;
  userId: string;
  createDate: Timestamp;
  paymentSupplier: PaymentSuppliers;
  paymentCurrency: string;
  paymentMethodId?: string | null;
  saveForLaterUse: boolean;
  amount: number;
  purpose: PaymentPurpose;
  purposeId: string;
  wasCompleted: boolean;

  paymentSupplierTransactionId?: string;
  paymentSupplierPaymentId?: string;
  error?: string;
  storeId: string;
}
