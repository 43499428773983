<ng-container *ngIf="isLoading">
  <app-loader></app-loader>
</ng-container>

<div class="modal-header">
  <button type="button" class="close-btn" aria-label="Close" (click)="modal.dismiss()">
    Close
  </button>
  <h4 class="modal-title text-center" id="modal-title">
    {{ !storeId ? 'Create New Store' : 'Edit Store' }}
  </h4>
</div>
<div class="modal-body">
  <form name="storeForm" #storeForm="ngForm">
    <div class="form-row justify-content-center">
      <div class="col-10">
        <div class="form-group">
          <label for="name">Name*</label>
          <input
            type="text"
            class="form-control"
            id="name"
            name="name"
            placeholder="Name"
            autocomplete="off"
            [(ngModel)]="store.name"
            required
            maxlength="100"
          />
        </div>

        <div class="form-group">
          <label for="url">Store Url*</label>
          <input
            type="text"
            class="form-control"
            id="url"
            name="url"
            placeholder="Store Url"
            autocomplete="off"
            [(ngModel)]="store.url"
            required
            maxlength="100"
          />
        </div>

        <div class="form-group" id="store-currency">
          <label for="StoreCurrency">Store Currency*</label>
          <ng-select
            id="StoreCurrency"
            name="Store Currency"
            placeholder="Store Currency"
            [items]="currencies"
            bindLabel="name"
            [(ngModel)]="currency"
            autocomplete="off"
            required
            [clearable]="false"
            appendTo="#store-currency"
            [style.position]="'relative'"
          >
          </ng-select>
        </div>

        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="haveTheme"
              name="haveTheme"
              [(ngModel)]="haveTheme"
            />
            <label class="custom-control-label" for="haveTheme">Have Theme</label>
          </div>
        </div>

        <div *ngIf="haveTheme" class="form-group">
          <label for="primary">Primary Color</label>
          <input
            type="color"
            class="form-control"
            id="primary"
            name="primary"
            placeholder="Store primary color"
            autocomplete="off"
            [(ngModel)]="theme.primaryColor"
          />
        </div>

        <div *ngIf="haveTheme" class="form-group">
          <label for="secondary">Secondary Color</label>
          <input
            type="color"
            class="form-control"
            id="secondary"
            name="secondary"
            placeholder="Store secondary color"
            autocomplete="off"
            [(ngModel)]="theme.secondaryColor"
          />
        </div>

        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="isPublished"
              name="isPublished"
              [(ngModel)]="store.isPublished"
            />
            <label class="custom-control-label" for="isPublished">Is Published</label>
          </div>
        </div>
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="requiresLoginByDefault"
              name="requiresLoginByDefault"
              [(ngModel)]="store.sessionActionsRequireLoginDefault"
            />
            <label class="custom-control-label" for="requiresLoginByDefault"
              >Session actions require login by default</label
            >
          </div>
        </div>
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="updateCoriunder"
              name="updateCoriunder"
              [(ngModel)]="updateCoriunder"
            />
            <label class="custom-control-label" for="updateCoriunder"
              >Set Coriunder Keys? (will rewrite current set up for the store)</label
            >
          </div>
        </div>

        <div *ngIf="updateCoriunder" class="form-group">
          <label for="merchantnumber">Merchant Number</label>
          <input
            type="number"
            class="form-control"
            id="merchantnumber"
            name="merchantnumber"
            placeholder="1234567"
            autocomplete="off"
            required
            [(ngModel)]="coriunder.merchantnumber"
          />
        </div>

        <div *ngIf="updateCoriunder" class="form-group">
          <label for="hashkey">Hash Key</label>
          <input
            type="password"
            class="form-control"
            id="hashkey"
            name="hashkey"
            placeholder="**********"
            autocomplete="off"
            required
            [(ngModel)]="coriunder.personalhashkey"
          />
        </div>
        <div class="form-group">
          <label for="External">Store External Url</label>
          <input
            type="url"
            class="form-control"
            id="External"
            name="External"
            placeholder="Store External Url"
            autocomplete="on"
            [(ngModel)]="externalUrl"
            maxlength="100"
          />
        </div>
        <div
          class="form-group"
          *ngIf="
            currency.paymentSupplier === PaymentSuppliers.SDKWithRedirect ||
            currency.paymentSupplier === PaymentSuppliers.WooCommerce
          "
        >
          <label for="checkoutRedirectRoute">Store Checkout Redirect Route</label>
          <input
            type="url"
            class="form-control"
            id="checkoutRedirectRoute"
            name="checkoutRedirectRoute"
            placeholder="/Route-Url"
            autocomplete="on"
            [(ngModel)]="store.checkoutRedirectRoute"
            maxlength="100"
          />
        </div>

        <div class="form-group" *ngIf="showStoreTokenField">
          <label for="utokenl">Store Token*</label>
          <input
            type="text"
            class="form-control"
            id="token"
            name="token"
            placeholder="Store Token"
            autocomplete="off"
            [(ngModel)]="token"
            minlength="3"
            maxlength="100"
          />
          <ng-container *ngIf="{getPassed: isT1CredentialsAreValid$ | async} as valuesObj">
            <span *ngIf="valuesObj.getPassed === null">Checking token...</span>
            <span *ngIf="valuesObj.getPassed === false" class="text-danger">
              Token is invalid {{ errorMessage ? ' - ' + errorMessage : '' }}
            </span>
            <span *ngIf="valuesObj.getPassed === true" class="text-success"> Token is valid </span>
          </ng-container>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-yellow w-132"
    (click)="save(storeForm)"
    [disabled]="isSubmitting"
  >
    Save
  </button>
</div>
