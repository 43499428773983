import {Component, inject, Input, OnInit} from '@angular/core';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {Timestamp} from '@angular/fire/firestore';
import {finalize, first, from, map, Observable, of, switchMap, tap} from 'rxjs';

import type {
  PlatformMediaFile,
  PlatformMediaCustomableName,
} from '../../../../../../shared/db-models/media';
import {AnimationOptions} from 'ngx-lottie';

@Component({
  selector: 'app-media-file-manager',
  template: `<div>
      {{ platformMediaCustomableName }}
      <input type="file" aria-label="file input" (change)="onFileSelected($event)" />
      <button (click)="onUpload()">Upload</button>
      <button (click)="onClear()">Discard</button>
      <div *ngIf="uploadProgress$ | async as progress">Progress: {{ progress }}%</div>
    </div>

    <div *ngIf="docData$ | async as doc">
      <div *ngIf="this.fetchFile$ | async as options">
        <ng-lottie
          *ngIf="doc.mediaKind.includes('json')"
          [options]="lottieOptions"
          width="100px"
          height="100px"
        >
        </ng-lottie>
      </div>
      <video *ngIf="doc.mediaKind.includes('video')" controls width="414" height="896">
        <source [src]="doc.url" [type]="doc.mediaKind" />
      </video>
      <img
        *ngIf="doc.mediaKind.includes('image')"
        [src]="doc.url"
        width="100"
        height="100"
        alt="{{ doc.url }}"
      />
      <a
        *ngIf="
          !doc.mediaKind.includes('json') &&
          !doc.mediaKind.includes('video') &&
          !doc.mediaKind.includes('image')
        "
        [href]="doc.url"
        target="_blank"
        >{{ doc.mediaKind }}: {{ doc.url }}</a
      >
    </div>`,
  styleUrls: [],
})
export class MediaFileManager implements OnInit {
  private _fs = inject(AngularFirestore);

  @Input() platformMediaCustomableName: PlatformMediaCustomableName;

  selectedFile: File | null = null;
  uploadProgress$: Observable<number | undefined> | undefined;

  private _collection = this._fs.collection<PlatformMediaFile>('media');
  private _doc: ReturnType<MediaFileManager['_collection']['doc']>;
  public docData$: Observable<PlatformMediaFile>;
  public fetchFile$: Observable<string>;

  get fileName() {
    return this.selectedFile?.name;
  }
  startWith(match: string, str: string) {
    return str.startsWith(match);
  }

  public lottieOptions: AnimationOptions;

  constructor(private storage: AngularFireStorage) {}

  ngOnInit() {
    if (!this.platformMediaCustomableName) return;
    this._doc = this._collection.doc<PlatformMediaFile>(this.platformMediaCustomableName);
    this.docData$ = this._doc.valueChanges() as Observable<PlatformMediaFile>;
    this.fetchFile$ = this.docData$.pipe(
      switchMap((data) => {
        if (data.mediaKind.includes('json')) return from(fetch(data.url).then((res) => res.json()));
        return of(null);
      }),
      tap(async (data) => {
        this.lottieOptions = {animationData: data};
      }),
      map((data) => JSON.stringify(data))
    );
  }

  onFileSelected(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    const file = files[0] ?? null;
    const fileEnding = file.name.split('.').pop();
    const renamedFile = new File([file], this.platformMediaCustomableName + '.' + fileEnding, {
      type: file.type,
    });
    this.selectedFile = renamedFile;
  }

  onUpload() {
    if (this.selectedFile) {
      const filePath = `media/${this.platformMediaCustomableName}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, this.selectedFile);
      this.uploadProgress$ = task.percentageChanges();
      task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            fileRef
              .getDownloadURL()
              .pipe(first())
              .subscribe((url) => {
                this._doc.set({
                  key: this.platformMediaCustomableName,
                  url,
                  mediaKind: !!this.selectedFile?.type
                    ? this.selectedFile.type
                    : 'application/json',
                  uploadedAt: Timestamp.now(),
                } as PlatformMediaFile);
              });
          })
        )
        .subscribe();
    }
  }

  onClear() {
    const filePath = `media/${this.platformMediaCustomableName}`;
    this.storage
      .ref(filePath)
      .delete()
      .subscribe(() => this._doc.delete());
    this.uploadProgress$ = undefined;
  }
}
