import type firebase from 'firebase';
type Timestamp = firebase.firestore.Timestamp;

export type SMSData = {
  sendWhen: Timestamp;
  wasSent: boolean;
  smsBody: string;
  phoneNumbers: string[];
  provider?: SMSProvider;
  selectedStoresIds: string[];
  selectedSessionsIds: string[];
  defaultCountryCode?: string;
  isTest: boolean;
  successes?: number;
  failed?: number;
  total?: number;
};

export enum SMSProvider {
  Twilio = 'Twilio',
  AWS = 'AWS',
}
