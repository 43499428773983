// Work around for https://github.com/firebase/firebase-js-sdk/issues/4110
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import 'firebase/compat/database';
import {environment} from '../environments/environment';

const app = firebase.initializeApp(environment.firebaseConfig);
if (environment.useEmulators) {
  app.auth().useEmulator('http://localhost:9099');
  app.firestore().useEmulator('localhost', 8080);
  app.functions().useEmulator('localhost', 5001);
  app.storage().useEmulator('localhost', 9199);
  app.database().useEmulator('localhost', 8090);
}
