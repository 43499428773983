<div class="container-lg">
  <div class="row">
    <div class="col-12">
      <section class="settings-page">
        <!-- Page cover -->
        <div class="page-cover page-cover-user">
          <h1 class="welcome-text">Stores Management</h1>
        </div>

        <ng-container *ngIf="!isLoading">
          <app-list-view [settings]="listViewSettings">
            <ng-template appItemActionsContent let-item="item" let-index="index">
              <label [for]="'files' + index" class="btn btn-info"> Upload Products </label>
              <input
                [id]="'files' + index"
                type="file"
                aria-label="file input"
                (change)="addProducts($event, item)"
                class="d-none"
              />
            </ng-template>
          </app-list-view>
        </ng-container>
      </section>
    </div>
  </div>
</div>
