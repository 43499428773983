import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {NgForm} from '@angular/forms';
import {finalize} from 'rxjs/operators';
import {UsersService} from '../../services/users.service';
import AdminHelpers from '../../helpers/helpers';

@Component({
  selector: 'app-admin-user-modal',
  templateUrl: './add-admin-user-modal.component.html',
})
export class AddAdminUserModalComponent implements OnInit {
  isSubmitting: boolean;
  showPhone: boolean = false;
  email: string;
  phoneNumber: string;

  constructor(
    public modal: NgbActiveModal,
    private usersService: UsersService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {}

  public save(f: NgForm): void {
    if (f.invalid) {
      AdminHelpers.markFormInvalidAndFocus(f);
    } else {
      this.isSubmitting = true;
      f.form.disable();

      if (!this.phoneNumber) {
        this.usersService
          .setUserAsSuperAdmin(this.email)
          .pipe(
            finalize(() => {
              this.isSubmitting = false;
              f.form.enable();
            })
          )
          .subscribe(
            () => {
              this.toastr.success('The user has been assigned as a user admin');
              this.modal.close();
            },
            (err) => {
              if (err.message == 'ERROR.USER_DONT_EXIST') {
                this.toastr.toastrConfig.autoDismiss = false;
                this.toastr.warning('User Does Not Exist, Try To Create The User');
                this.showPhone = true;
                this.toastr.toastrConfig.autoDismiss = true;
                return;
              }

              this.toastr.error(err.message);
            }
          );
        return;
      }

      this.usersService
        .createUserAsSuperAdmin(this.email, this.phoneNumber)
        .pipe(
          finalize(() => {
            this.isSubmitting = false;
            f.form.enable();
          })
        )
        .subscribe(
          () => {
            this.toastr.success('The user has been assigned as a user admin');
            this.modal.close();
          },
          (err) => {
            this.toastr.error(err.message);
          }
        );
    }
  }
}
