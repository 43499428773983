import {Component, OnInit} from '@angular/core';
import {
  ListViewResults,
  ListViewSettings,
  ListViewTableColumnType,
} from '../../interfaces/list-view-models';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable, Subject, from, map, of, switchMap} from 'rxjs';
import {ServerResponse} from '../../interfaces/server-response';
import {DbStoreModel} from '../../../../../shared/db-models/store';
import {StoresService} from '../../services/stores.service';
import {EditStoreModalComponent} from '../../modals/edit-store-modal/edit-store-modal.component';
import {AddProductsStrategySelectModalComponent} from 'src/app/modals/add-products-modal/add-products-modal.component';
import {ToastrService} from 'ngx-toastr';
import {CSV_Strategy} from '../../../../../shared/ecommerce-platform-integration/csv-strategies';

@Component({
  selector: 'app-stores',
  templateUrl: './stores.component.html',
})
export class StoresComponent implements OnInit {
  isLoading = true;

  public listViewSettings: ListViewSettings = {
    allowCreate: true,
    allowEdit: true,
    allowGalleryMode: false,
    createButtonText: 'Create New Store',
    isGalleryDefault: false,
    hideSearch: true,
    tableSettings: {
      columns: [
        {
          name: 'id',
          displayText: 'Id',
          columnType: ListViewTableColumnType.Text,
          allowSort: false,
        },
        {
          name: 'name',
          displayText: 'Name',
          columnType: ListViewTableColumnType.Text,
          allowSort: false,
        },
        {
          name: 'url',
          displayText: 'Url',
          columnType: ListViewTableColumnType.Text,
          allowSort: false,
        },
        {
          name: 'isPublished',
          displayText: 'Is Published?',
          columnType: ListViewTableColumnType.Boolean,
          allowSort: false,
        },
      ],
      showExpand: false,
    },
    defaultSortColumn: 'id',
    getData: () => this.getData(),
    create: () => this.create(),
    edit: (store: DbStoreModel) => this.edit(store),
  };

  constructor(
    private storesService: StoresService,
    private modalService: NgbModal,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.isLoading = false;
  }

  private getData(): Observable<ServerResponse<ListViewResults<DbStoreModel>>> {
    const result = new Subject<ServerResponse<ListViewResults<DbStoreModel>>>();

    this.storesService.getAllStores().subscribe(
      (response) => {
        const stores = response.docs.map((x) => {
          const currStore = x.data();
          currStore.id = x.id;
          return currStore;
        });

        result.next({
          result: true,
          data: {
            data: stores,
            dataCount: stores.length,
          },
        });
        result.complete();
      },
      (err) => {
        result.error(err);
        result.complete();
      }
    );

    return result.asObservable();
  }

  private create(): Observable<boolean> {
    const createResult = new Subject<boolean>();

    const modal = this.modalService.open(EditStoreModalComponent, {
      size: 'lg',
      backdrop: 'static',
      windowClass: 'dark-modal ltr',
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
    });

    modal.result
      .then(
        () => {
          createResult.next(true);
        },
        () => {
          createResult.next(false);
        }
      )
      .catch(() => {
        createResult.next(false);
      })
      .finally(() => {
        createResult.complete();
      });

    return createResult;
  }
  private onFileSelected(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    const file = files[0] ?? null;
    return file;
  }
  public async addProducts(event: Event, store: DbStoreModel) {
    const file = this.onFileSelected(event);
    from(
      new Promise<unknown[]>(async (resolve, reject) => {
        const xlsx = await import('xlsx');

        const reader = file.stream().getReader();

        reader
          .read()
          .then((e) => {
            const data = e.value;
            const workbook = xlsx.read(data, {type: 'array'});
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const products = xlsx.utils.sheet_to_json(worksheet, {blankrows: false});
            resolve(products);
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      })
    )
      .pipe(
        switchMap((products) => {
          const modal = this.modalService.open(AddProductsStrategySelectModalComponent, {
            size: 'lg',
            backdrop: 'static',
            windowClass: 'dark-modal ltr',
            ariaLabelledBy: 'modal-basic-title',
            centered: true,
          });

          (modal.componentInstance as AddProductsStrategySelectModalComponent).products = products;
          (modal.componentInstance as AddProductsStrategySelectModalComponent).storeId = store.id;
          (modal.componentInstance as AddProductsStrategySelectModalComponent).storeName =
            store.name;

          return from(modal.result as Promise<CSV_Strategy>).pipe(
            map((strategy) => ({strategy, products}))
          );
        }),
        switchMap(({strategy, products}) => {
          if (!!strategy) {
            return this.storesService.addProducts(store.id, strategy, products);
          }
          return of(false);
        })
      )
      .subscribe({
        next: (result) => {
          if (result) {
            this.toastrService.success('Products added successfully');
          } else {
            this.toastrService.error('Error adding products');
          }
        },
        error: (err) => {
          console.error(err);
          this.toastrService.error('Error adding products');
        },
      });
  }

  private edit(store: DbStoreModel): Observable<boolean> {
    const createResult = new Subject<boolean>();

    const modal = this.modalService.open(EditStoreModalComponent, {
      size: 'lg',
      backdrop: 'static',
      windowClass: 'dark-modal ltr',
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
    });

    (modal.componentInstance as EditStoreModalComponent).storeId = store.id;

    modal.result
      .then(
        () => {
          createResult.next(true);
        },
        () => {
          createResult.next(false);
        }
      )
      .catch(() => {
        createResult.next(false);
      })
      .finally(() => {
        createResult.complete();
      });

    return createResult;
  }
}
