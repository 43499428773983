import {Client} from '../../../shared/utilities/type-helpers';
import {environment as env} from './environment.live';
const client: Client = {clientType: 'claro'};

export const environment = {
  ...env,
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  firebaseConfig: {
    apiKey: 'AIzaSyASO44CqXk5TBiE6CJdj7xaX11_J-lh6lc',
    authDomain: 'claro-shadow.firebaseapp.com',
    databaseURL: 'https://claro-shadow-default-rtdb.firebaseio.com',
    projectId: 'claro-shadow',
    storageBucket: 'claro-shadow.appspot.com',
    messagingSenderId: '189554529105',
    appId: '1:189554529105:web:578eaf61d2a2f9c6c4b970',
    measurementId: 'G-DYJQM5LMVP',
  },
  client,
};
