<section class="login-page">
  <!-- Form -->
  <div class="form-container">
    <form class="form-signin" #loginForm="ngForm" (ngSubmit)="login(loginForm)">
      <h1 class="login-title">Terrific Login</h1>
      <p class="login-description">Please enter your credentials to login</p>

      <button
        class="btn-login"
        type="button"
        (click)="signInWithProvider(loginForm, 'Google')"
        [disabled]="isSubmitting"
      >
        <img alt="Google" class="icon" src="assets/images/google-g.svg" />
        <div class="seperator"></div>
        Sign in with Google
      </button>
      <!-- <button
        class="btn-login"
        type="button"
        (click)="signInWithProvider(loginForm, 'Facebook')"
        [disabled]="isSubmitting"
      >
        <img alt="Facebook" class="icon" src="assets/images/facebook-f.svg" />
        <div class="seperator"></div>
        Sign in with Facebook
      </button> -->

      <div class="form-group">
        <label for="inputEmail">Email</label>
        <input
          type="email"
          id="inputEmail"
          name="inputEmail"
          class="form-control"
          placeholder="samplemail@mail.com"
          [(ngModel)]="email"
          required
          autofocus
        />
      </div>

      <div class="form-group">
        <label for="inputPassword">Password</label>
        <input
          type="password"
          id="inputPassword"
          name="inputPassword"
          class="form-control"
          placeholder="••••••••••"
          [(ngModel)]="password"
          required
        />
      </div>

      <div class="form-group">
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            id="inputRememberMe"
            name="inputRememberMe"
            [(ngModel)]="rememberMe"
          />
          <label class="custom-control-label" for="inputRememberMe">Remember me</label>
        </div>
      </div>

      <button class="btn btn-lg btn-block btn-login mt-4" type="submit">Login</button>
    </form>
  </div>
  <!-- Login image -->
  <div class="image-container"></div>
</section>
