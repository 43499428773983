<div class="container-lg">
  <div class="row">
    <div class="col-12">
      <section class="sms-page">
        <!-- Page cover -->
        <div class="page-cover page-cover-user">
          <h1 class="welcome-text">SMS</h1>
        </div>

        <form class="form">
          <div class="sms-container">
            <div class="sms-option">
              <label>Phone numbers:</label>
              <p>A comma should be used to separate the number from the number.</p>
              <input type="text" #phoneNum class="text-sms" id="phones" cols="30" rows="10" />
            </div>
            <div class="sms-option">
              <label
                >Store ids, separated by comma - pick users by the store they registered to</label
              >
              <input id="sms-stores" #stores class="form-control" />
            </div>
            <div class="sms-option">
              <label>Session ids - pick users by the session they registered to</label>
              <input #sessions id="sms-sessions" class="form-control" />
            </div>
            <div class="sms-option">
              <label>Text message:</label>
              <textarea #message class="text-sms" id="phones" cols="30" rows="10"></textarea>
            </div>
            <div class="sms-option">
              <label>Timing message:</label>
              <input #date type="datetime-local" class="form-control" />
            </div>
            <div class="sms-option">
              <label>Country Code: eg: US, MX, IL, etc'</label>
              <input #prefix type="text" class="form-control" />
            </div>
            <div class="sms-option">
              <label
                >is a test? - sms will not be sended, and total response count + phone numbers will
                be provided</label
              >
              <input #isTest type="checkbox" class="form-control" />
            </div>
            <div class="sms-option">
              <label for="sms-service-provider">Provider</label>
              <select #provider id="sms-service-provider" class="form-control">
                <option *ngFor="let provider of providers" [value]="provider">
                  {{ provider }}
                </option>
              </select>
            </div>
          </div>
          <div class="link-and-send-btn">
            <p>Hold down the Ctrl (windows) or Command (Mac) button to select multiple options.</p>
            <label for="submit">Send SMS</label>
            <input
              type="submit"
              (click)="
                sendSms(
                  phoneNum.value,
                  message.value,
                  date.value,
                  provider.value,
                  prefix.value,
                  isTest.checked,
                  sessions.value,
                  stores.value
                )
              "
              class="send-sms"
            />
            <p>number of phones: {{ numberOfPhones }}</p>
          </div>
        </form>
      </section>
      <section>
        <div id="messageList">
          <a>list of messages</a>
          <ul *ngFor="let message of messages$ | async">
            <li>{{ message }}</li>
          </ul>
        </div>
      </section>
    </div>
  </div>
</div>
