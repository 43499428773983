import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import './firebase-initialization';
import {environment} from '../environments/environment';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {AngularFireStorageModule} from '@angular/fire/compat/storage';
import {AngularFirestore, AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {AngularFireFunctionsModule} from '@angular/fire/compat/functions';
import {AuthLayoutComponent} from './layouts/auth-layout/auth-layout.component';
import {AppLayoutComponent} from './layouts/app-layout/app-layout.component';
import {LoginComponent} from './pages/login/login.component';
import {HomeComponent} from './pages/home/home.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {
  ActionContentDirective,
  ExpandContentDirective,
  ItemActionsContentDirective,
  ListViewComponent,
} from './components/list-view/list-view.component';
import {ConfirmModalComponent} from './modals/confirm-modal/confirm-modal.component';
import {AdminUsersComponent} from './pages/admin-users/admin-users.component';
import {LoaderComponent} from './components/loader/loader.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {ToastrModule} from 'ngx-toastr';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {SwiperModule} from 'swiper/angular';
import {AddAdminUserModalComponent} from './modals/add-admin-user-modal/add-admin-user-modal.component';
import {StoresComponent} from './pages/stores/stores.component';
import {EditStoreModalComponent} from './modals/edit-store-modal/edit-store-modal.component';
import {CurrenciesComponent} from './pages/currencies/currencies.component';
import {EditCurrencyModalComponent} from './modals/edit-currency-modal/edit-currency-modal.component';
import {DeleteUserComponent} from './pages/delete-user/delete-user.component';
import {DeleteUserModalComponent} from './modals/delete-user-modal/delete-user-modal.component';
import {SmsCreationComponent} from './pages/sms-creation/sms-creation.component';
import {NgbModule, NgbNavModule} from '@ng-bootstrap/ng-bootstrap';
import {MediaFileManager} from './pages/cms/media-file-manager/media-file-manager.component';
import {LegalContentManager} from './pages/cms/legal-content-manager/legal-content-manager.component';
import {LottieModule} from 'ngx-lottie';
import {CmsComponent} from './pages/cms/cms.component';
import {PlatformTextPropertiesFormComponent} from './pages/cms/platform-text-properties-form-component/platform-text-properties-form-component.component';
import {AddProductsStrategySelectModalComponent} from './modals/add-products-modal/add-products-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    AppLayoutComponent,
    LoginComponent,
    HomeComponent,
    NotFoundComponent,
    ListViewComponent,
    ActionContentDirective,
    ExpandContentDirective,
    ItemActionsContentDirective,
    LoaderComponent,
    ConfirmModalComponent,
    AdminUsersComponent,
    AddAdminUserModalComponent,
    StoresComponent,
    EditStoreModalComponent,
    CurrenciesComponent,
    EditCurrencyModalComponent,
    DeleteUserComponent,
    DeleteUserModalComponent,
    SmsCreationComponent,
    MediaFileManager,
    CmsComponent,
    PlatformTextPropertiesFormComponent,
    LegalContentManager,
    AddProductsStrategySelectModalComponent,
  ],
  imports: [
    LottieModule.forRoot({player: () => import('lottie-web')}),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    FormsModule,
    ReactiveFormsModule,
    SwiperModule,
    NgbModule,
    NgSelectModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
    }),
    FontAwesomeModule,
    NgbNavModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(afs: AngularFirestore) {
    console.log('angular started');
    afs.firestore.app.firestore().settings({
      merge: true,
      experimentalForceLongPolling: environment.featureFlags.longPolling,
      experimentalAutoDetectLongPolling: !environment.featureFlags.longPolling,
      ignoreUndefinedProperties: true,
    });
  }
}
